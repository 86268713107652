<script setup>
import {Link, router} from "@inertiajs/vue3";
import dayjs from "dayjs";
import AvatarImage from "@/Components/AvatarImage.vue";
import PrimaryLightButton from "@/Components/PrimaryLightButton.vue";
import DangerLightButton from "@/Components/DangerLightButton.vue";
import {ref} from "vue";
import axios from "axios";

const props = defineProps({
    notification: Object,
});
const loading = ref(false)
const loadingImmortalized = ref(false)
const loadingFriendship = ref(false)
const loadingMentorFriendship = ref(false)
const loadingTimepiece = ref(false)

const approve = (id) => {
    if (props.notification.type === 'request-friendship') {
        loadingFriendship.value = true;
        router.post(route('accept.friendship'), {
            id: id,
            type: props.notification.type,
            onSuccess: page => {
                loadingFriendship.value = false;
            },
            onError: error => {
                loadingFriendship.value = false;
            }
        })
    } else {
        loadingTimepiece.value = true;
        axios.post(route('accept.timepiece'), {
            id: id,
            type: props.notification.type,
        }).then((res) => {
            loadingTimepiece.value = false;
        }).catch(() => {
            loadingTimepiece.value = false;
        })
    }
}
const decline = (id) => {
    if (props.notification.type === 'request-friendship') {
        loadingFriendship.value = true;
        router.post(route('decline.friendship'), {
            id: id,
            type: props.notification.type,
            onSuccess: page => {
                loadingFriendship.value = false;
            },
            onError: error => {
                loadingFriendship.value = false;
            }
        })
    } else {
        loadingTimepiece.value = true;
        axios.post(route('decline.timepiece'), {
            id: id,
            type: props.notification.type,
        }).then((res) => {
            loadingTimepiece.value = false;
        }).catch(() => {
            loadingTimepiece.value = false;
        })
    }
}
const approveImmortalizedFriendship = (id, immortalized_id) => {
    loadingImmortalized.value = true;
    router.post(route('accept.friendship'), {
        id: id,
        immortalized_profile_id: immortalized_id,
        onSuccess: page => {
            loadingImmortalized.value = false;
        },
        onError: error => {
            loadingImmortalized.value = false;
        },
    })
}
const declineImmortalizedFriendship = (id, immortalized_id) => {
    loadingImmortalized.value = true;
    router.post(route('decline.friendship'), {
        id: id,
        immortalized_profile_id: immortalized_id,
        onSuccess: page => {
            loadingImmortalized.value = false;
        },
        onError: error => {
            loadingImmortalized.value = false;
        }
    })
}

const approveMentorFriendship = (id) => {
    loadingMentorFriendship.value = true;
    router.post(route('accept.mentor.friendship'), {
        id: id,
        onSuccess: page => {
            loadingMentorFriendship.value = false;
        },
        onError: error => {
            loadingMentorFriendship.value = false;
        },
    })
}
const declineMentorFriendship = (id) => {
    loadingMentorFriendship.value = true;
    router.post(route('decline.mentor.friendship'), {
        id: id,
        onSuccess: page => {
            loadingMentorFriendship.value = false;
        },
        onError: error => {
            loadingMentorFriendship.value = false;
        }
    })
}

const approveTrusteeInvitation = (id) => {
    loading.value = true;
    router.post(route('trustee.accept.invitation'), {
        id: id,
        onSuccess: page => {
            loading.value = false;
        },
        onError: error => {
            loading.value = false;
        }
    })
}
const declineTrusteeInvitation = (id) => {
    loading.value = true;
    router.post(route('trustee.decline.invitation'), {
        id: id,
        onSuccess: page => {
            loading.value = false;
        },
        onError: error => {
            loading.value = false;
        }
    })
}

</script>

<template>
    <div class="flex flex-row justify-start items-stretch gap-3 cursor-pointer">
        <div class="flex flex-col items-center min-w-[48px]">
            <Link :href="route('show.profile', {slug: props.notification.data.sender_profile_slug})" method="get">
                <div class="relative">
                    <avatar-image  :lg="true" :profile-photo="props.notification.data.sender_photo"></avatar-image>
                    <div v-if="props.notification.data.icon" class="p-1 absolute right-0 bottom-0 bg-teal-50 rounded-full outline outline-white outline-2">
                        <inline-svg class="border-none" width="12" height="12" :src="'/storage/icons/notifications/' + props.notification.data.icon"></inline-svg>
                    </div>
                </div>
            </Link>
            <div class="w-0.5 flex-1 min-h-[16px] mt-1 mb-1 bg-gray-200"></div>
        </div>
        <div class="flex flex-col justify-start items-start flex-1">
            <div class="text-slate-700 text-sm font-semibold font-lora leading-tight">
                <Link :href="route('show.profile', {slug: props.notification.data.sender_profile_slug})" method="get">
                    {{props.notification.data?.sender_name}}
                </Link>
                <span v-if="props.notification.created_at" class="text-slate-600 text-xs font-normal leading-none ml-2">
                    {{dayjs().to(dayjs(props.notification.created_at))}}
                </span>
            </div>
            <div class="text-slate-600 text-sm font-normal leading-tight mb-8 break-words break-all whitespace-normal">
                {{props.notification.data?.text}}
                <Link v-if="props.notification.type.includes('feed') && props.notification.type.includes('timepiece')"
                      class="text-teal-700 font-semibold font-lora break-words break-all whitespace-normal"
                      :href="route('show.profile', {slug: props.notification.data?.immortalized_profile_slug, timepiece_id: props.notification.data?.object_id})"
                >
                    {{props.notification.data?.object_title}}
                </Link>
                <Link v-else-if="props.notification.type.includes('timepiece')"
                      class="text-teal-700 font-semibold font-lora break-words break-all whitespace-normal"
                      :href="route('show.profile', {slug: $page.props.auth.user.main_profile.slug, timepiece_id: props.notification.data?.object_id})"
                >
                    {{props.notification.data?.object_title}}
                </Link>
                <Link v-else-if="props.notification.type === 'feed-remembrance-comment'"
                      class="text-teal-700 font-semibold font-lora break-words break-all whitespace-normal"
                      :href="route('remembrance.profile', { 'slug': props.notification.data?.immortalized_profile_slug })"
                >
                    {{props.notification.data?.immortalized_profile_name}}
                </Link>
                <Link v-else-if="props.notification.type === 'new-message'"
                      class="text-teal-700 font-semibold font-lora break-words break-all whitespace-normal"
                      :href="route('my.profile', {'viewMyCircle': true, 'viewConversationWithProfileId': props.notification.data?.sender_profile_id})"
                >
                    {{props.notification.data?.object_title}}
                </Link>
                <Link v-else
                      class="text-teal-700 font-semibold font-lora break-words break-all whitespace-normal"
                      :href="route('show.profile', {slug: props.notification.data.sender_profile_slug})"
                >
                    {{props.notification.data?.object_title}}
                </Link>
                <div v-if="props.notification.type === 'request-friendship' && props.notification.data.object_status === 'pending'"
                     class="flex flex-row gap-2 mt-3"
                >
                    <PrimaryLightButton @click="approve(props.notification.data.sender_profile_id)" :loading="loadingFriendship">
                        <inline-svg class="mr-2" src="/storage/icons/check.svg"/>
                        <span>Accept</span>
                    </PrimaryLightButton>
                    <DangerLightButton @click="decline(props.notification.data.sender_profile_id)" :loading="loadingFriendship">
                        <inline-svg class="mr-2" width="22" height="22" stroke="red" src="/storage/icons/x-close.svg"></inline-svg>
                        <span>Decline</span>
                    </DangerLightButton>
                </div>
                <div v-else-if="props.notification.type === 'request-immortalized-friendship' && props.notification.data.object_status === 'pending'"
                     class="flex flex-row gap-2 mt-3"
                >
                    <PrimaryLightButton @click="approveImmortalizedFriendship(props.notification.data.sender_profile_id, props.notification.data.immortalized_profile_id)"
                                        :loading="loadingImmortalized"
                    >
                        <inline-svg class="mr-2" src="/storage/icons/check.svg"/>
                        <span>Accept</span>
                    </PrimaryLightButton>
                    <DangerLightButton @click="declineImmortalizedFriendship(props.notification.data.sender_profile_id, props.notification.data.immortalized_profile_id)"
                                       :loading="loadingImmortalized"
                    >
                        <inline-svg class="mr-2" width="22" height="22" stroke="red" src="/storage/icons/x-close.svg"></inline-svg>
                        <span>Decline</span>
                    </DangerLightButton>
                </div>
                <div v-else-if="props.notification.type === 'request-mentor-friendship' && props.notification.data.object_status === 'pending'"
                     class="flex flex-row gap-2 mt-3"
                >
                    <PrimaryLightButton @click="approveMentorFriendship(props.notification.data.sender_profile_id)"
                                        :loading="loadingMentorFriendship"
                    >
                        <inline-svg class="mr-2" src="/storage/icons/check.svg"/>
                        <span>Accept</span>
                    </PrimaryLightButton>
                    <DangerLightButton @click="declineMentorFriendship(props.notification.data.sender_profile_id)"
                                       :loading="loadingMentorFriendship"
                    >
                        <inline-svg class="mr-2" width="22" height="22" stroke="red" src="/storage/icons/x-close.svg"></inline-svg>
                        <span>Decline</span>
                    </DangerLightButton>
                </div>
                <div v-else-if="(props.notification.type === 'request-timepiece'
                || props.notification.type === 'request-edited-timepiece'
                || props.notification.type === 'feed-timepiece-edited')
                && props.notification.data.object_status === 'pending'"
                     class="flex flex-row gap-2 mt-3"
                >
                    <PrimaryLightButton @click="approve(props.notification.data.object_id)" :loading="loadingTimepiece">
                        <inline-svg class="mr-2" src="/storage/icons/check.svg"/>
                        <span>Accept</span>
                    </PrimaryLightButton>
                    <DangerLightButton @click="decline(props.notification.data.object_id)" :loading="loadingTimepiece">
                        <inline-svg class="mr-2" width="22" height="22" stroke="red" src="/storage/icons/x-close.svg"></inline-svg>
                        <span>Decline</span>
                    </DangerLightButton>
                </div>
                <div v-else-if="props.notification.type === 'request-trustee' && props.notification.data.object_status === 'pending'"
                     class="flex flex-row gap-2 mt-3"
                >
                    <PrimaryLightButton @click="approveTrusteeInvitation(props.notification.data.object_id)" :loading="loading">
                        <inline-svg class="mr-2" src="/storage/icons/check.svg"/>
                        <span>Accept</span>
                    </PrimaryLightButton>
                    <DangerLightButton @click="declineTrusteeInvitation(props.notification.data.object_id)" :loading="loading">
                        <inline-svg class="mr-2" width="22" height="22" stroke="red" src="/storage/icons/x-close.svg"></inline-svg>
                        <span>Decline</span>
                    </DangerLightButton>
                </div>
            </div>
        </div>
        <div v-if="!props.notification.read_at" class="w-2 h-2 bg-emerald-500 rounded-full justify-end"></div>
    </div>
</template>

<style scoped>

</style>
