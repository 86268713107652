<script setup>
import { VueFinalModal } from "vue-final-modal";
import {onMounted, reactive, ref} from "vue";
import Tabs from "@/Components/Tabs.vue";
import Notification from "@/Components/Notifications/Notification.vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import LinkButton from "@/Components/LinkButton.vue";
dayjs.extend(RelativeTime);

const notifications = ref({
    general_notifications_unread_counter: 0,
    feed_notifications_unread_counter: 0,
    mentorship_notifications_unread_counter: 0,
    feed_notifications: null,
    general_notifications: null,
    mentorship_notifications: null,
});
const loading = reactive({
    data: false,
    readAll: false,
})

const emit = defineEmits(['closeNotificationsModal', 'readNotifications']);

onMounted(() => {
    getData();
})

const getData = () => {
    loading.data = true;
    axios.get(route('notifications.get'))
        .then(response => {
            loading.data = false;
            notifications.value = response.data
            const total_unread = response.data.feed_notifications_unread_counter + response.data.general_notifications_unread_counter + response.data.mentorship_notifications_unread_counter;
            emit('readNotifications', total_unread > 0)
        })
        .catch((err) => {
            loading.data = false;
        });
}

const readNotification = (notification) => {
    loading.readAll = true;
    axios.post(route('notifications.read'), notification)
        .then(response => {
            loading.readAll = false;
            getData();
        })
        .catch((err) => {
            loading.readAll = false;
        });
}
const readAllNotifications = (section = "") => {
    loading.readAll = true;
    axios.post(route('notifications.read.all'), {section: section})
        .then(response => {
            loading.readAll = false;
            getData();
        })
        .catch((err) => {
            loading.readAll = false;
        });
}

</script>

<template>
    <vue-final-modal
        content-class="absolute right-0 top-0 bottom-0 overflow-auto bg-white max-w-full sm:max-w-[90%] w-600"
        overlay-transition="vfm-fade"
        content-transition="vfm-slide-right"
    >
        <div class="h-full w-full mx-auto relative bg-white flex flex-col right-0 rounded-lg">
            <inline-svg class="absolute right-6 top-6 cursor-pointer"
                        src="/storage/icons/x-close.svg"
                        @click="() => emit('closeNotificationsModal')">
            </inline-svg>
            <div class="pt-6 px-6 flex flex-row items-center justify-between gap-4">
                <div class="text-teal-900 text-xl font-semibold font-lora leading-loose">Notifications</div>
                <LinkButton :loading="loading.readAll" @click="readAllNotifications()">Mark all as read</LinkButton>
            </div>
            <div class="flex flex-col flex-1 p-6">
                <Tabs
                      :tabs="[
                        {
                            title: 'General',
                            slotName: 'tab1',
                            counter: notifications.general_notifications_unread_counter
                        },
                        {
                            title: 'Feed',
                            slotName: 'tab2',
                            counter: notifications.feed_notifications_unread_counter
                        },
                        {
                            title: 'Mentorship',
                            slotName: 'tab3',
                            counter: notifications.mentorship_notifications_unread_counter
                        },
                    ]">
                    <template #tab1>
                        <div v-if="notifications.general_notifications?.data?.length" class="pt-1 pb-6 flex justify-end">
                            <LinkButton :loading="loading.readAll" @click="readAllNotifications('general')">Mark General as read</LinkButton>
                        </div>
                        <Notification v-for="notification in notifications.general_notifications?.data"
                                      @click="readNotification(notification)"
                                      :notification="notification">
                        </Notification>
                        <LoadingSpinner v-if="loading.data"></LoadingSpinner>
                    </template>
                    <template #tab2>
                        <div v-if="notifications.feed_notifications?.data?.length" class="pt-1 pb-6 flex justify-end">
                            <LinkButton :loading="loading.readAll" @click="readAllNotifications('feed')">Mark Feed as read</LinkButton>
                        </div>
                        <Notification v-for="notification in notifications.feed_notifications?.data"
                                      @click="readNotification(notification)"
                                      :notification="notification">
                        </Notification>
                        <LoadingSpinner v-if="loading.data"></LoadingSpinner>
                    </template>
                    <template #tab3>
                        <div v-if="notifications.mentorship_notifications?.data?.length" class="pt-1 pb-6 flex justify-end">
                            <LinkButton :loading="loading.readAll" @click="readAllNotifications('mentorship')">Mark Mentorship as read</LinkButton>
                        </div>
                        <Notification v-for="notification in notifications.mentorship_notifications?.data"
                                      @click="readNotification(notification)"
                                      :notification="notification">
                        </Notification>
                        <LoadingSpinner v-if="loading.data"></LoadingSpinner>
                    </template>
                </Tabs>
            </div>
        </div>
    </vue-final-modal>
</template>

