<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    href: String,
    active: Boolean,
});

const classes = computed(() => {
    return props.active
        ? 'border-teal-400 text-base font-medium leading-5 text-gray-600 focus:outline-none focus:border-teal-700 focus:shadow-none transition duration-150 ease-in-out'
        : 'border-transparent text-base font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:shadow-none focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out';
});
</script>

<template>
    <Link :href="href" :class="classes" class="inline-flex items-center px-1 pt-1 border-b-2">
        <slot />
    </Link>
</template>
