<template>
    <div class="fixed bottom-0 left-0 w-full bg-primary " v-if="display" style="z-index: 9999">
        <div class="px-4 sm:px-16 lg:px-28 max-w-7xl mx-auto">
            <div class="flex gap-12 flex-row sm:flex-row my-4 justify-between">
                <div class="flex-1 text-white">
                    <b>By using our platform you agree to our <Link class="underline underline-offset-4" :href="route('terms', {'slug': $page.props.footer_links['cookies']})">cookie policy</Link></b>
                </div>
                <div class="">
                    <SecondaryButton @click="closeConsent">{{ $t('Accept') }}</SecondaryButton>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import {Link, usePage} from '@inertiajs/vue3';

import {onMounted, ref} from 'vue';
import SecondaryButton from "@/Components/SecondaryButton.vue";

const page = usePage()

const display = ref(false)

const closeConsent = (index) => {
    createCookie('consent', 1, 30);
    display.value = false;
    // fetch(route('consent'))
    //     .then(() => {
    //         display.value = false;
    //         // window.location.reload()
    //     });
};

function createCookie(name, value, days, domain) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    if (domain) {
        domain = ";domain=" + domain;
    }
    document.cookie = name + "=" + value + expires + domain + "; SameSite=None; Secure; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

onMounted(() => {
    display.value = !readCookie('consent');
    // display.value = page.props.showConsent
});
</script>
